


























import Vue from "vue";
import Component from "vue-class-component";

import { Auth } from "@/store/modules";

@Component
export default class BuySubscription extends Vue {
  isLoading = false;
  plans = [];

  get redirectUrl() {
    return this.$route.query.redirectUrl as string || "";
  }

  async mounted() {
    this.isLoading = true;
    try {
      const plans = await this.$axios.get('/plans');
      this.plans = plans.data.filter((plan: any) => plan.active);
    } catch (err) {

    }
    this.isLoading = false;
  }

  async buy(slug: string) {
    this.isLoading = true;
    try {
      await Auth.buySubscription({ planSlug: slug, redirectUrl: this.redirectUrl });
    } catch (err) {
      window.alert(err.message);
    }
    this.isLoading = false;
  }
}
